.leftContent {
  height: 55vh;
  text-align: justify;
  overflow-y: scroll;
}

.rightContent {
  height: 55vh;
  background: #ffffff;
  border-radius: 30px;
  /* width: 385px; */
}

.rightMain {
  background: #e4ecf7;
  border-radius: 30px;
  height: 100%;
  text-align: center;
  font-size: 15px;
  color: #2b2b2b;
  
}

.mainDiv {
  background: #e4ecf7;
  border-radius: 30px;
}

::-webkit-scrollbar {
  height:5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ce1a1a;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  height: 1px !important;
  width: 1px !important;
  border-radius: 20px;
}

.markingNotation {
  background: #ffffff;
  border-radius: 20px;
  height: max-height;
  width: 100%;
  font-size: 12px;
  margin: auto;
}

.keyboard {
  height: 34vh;
  overflow-y: scroll;
}

.timer {
  background-color: var(--light-background);
}
