.swiper {
  width: 100%;
  height: 100%;


}


.swiper-slide {
 
  /* Center slide text vertically  */
  display: flex;
  flex-shrink: "calc(100% - 240px)";
  padding: 0px 15px;
  justify-content:flex-start;
  align-items: center; 
  padding-bottom: 30px;
}



.swiper-pagination-bullet {
  background-color:#0090FF;
 
}







