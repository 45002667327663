.active .nav-button {
    background: var( --blue-new);
    color: white;
   
    
  }

  .link{
    text-decoration: none;
    list-style: none;
  }
